<template>
  <div class="main">
    <div class="left">
      <p v-html="msg_left"></p>
    </div>
    <div class="right">
      <p v-html="msg_right"></p>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, onMounted } from "vue";
// 导入操作角色的API方法
import { details } from "../../api/webList";
import { useRoute } from "vue-router";

export default {
  name: "gather",
  setup() {
    // 在setup函数内部
    const route = useRoute();
    //定义数据
    let data = reactive({
      //表格数据
      msg_left: "",
      msg_right: "",
      id: "",
    });
    let getDetail = async () => {
      let params = {
        id: data.id,
      };
      let res = await details(params);
      console.log(res);
      data.msg_left = res.data.withExtra.web_content;
      data.msg_right = res.data.change_content;
    };
    onMounted(() => {
      console.log(route.query);
      data.id = route.query.id;
      getDetail();
    });

    return {
      ...toRefs(data),
      getDetail,
    };
  },
};
</script>
<style scoped lang="scss">

.main {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .left {
    border: 1px solid #a7a7a7;
    width: 800px;
    height: 700px;
    overflow: auto;
  }

  .right {
    border: 1px solid #a7a7a7;
    width: 800px;
    height: 700px;
    overflow: auto;
  }
}
</style>
